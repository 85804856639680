<template>
  <div id="about" class="contents cover">
    <h1 title="ソラシロについて">ABOUT</h1>
      <div class="intro">
          <h2 title="CONCEPT">広島を中心として活動する<br class="no-sp">デジタルコンテンツを制作する会社です</h2>
          <p class="">元コンシューマーゲーム制作会社、元オンラインゲーム制作会社、元アプリ制作会社、<br class="no-sp">
          元ウェブ制作会社、現役ゲーム系専門学校講師などの人で構成された、<br class="no-sp">
          ゲームが好きで、ゲーム（みたいなコンテンツ含む）を作りたい人が集まった会社です。<br class="no-sp">
          <!-- 現状、おかげさまでぞくぞくと受託業務をさせていただいておりますので、<br class="no-sp">自社のゲームが完成せずリリース出来ていない状況です（悲&nbsp;&nbsp;乞うご期待！-->
          </p>
      </div>
      <!-- 会社概要 -->
      <section class="right">
          <h2 title="会社概要">OVERVIEW</h2>
          <div class="content">
            <h3 data-aos="fade-left" data-aos-delay="300" title="OVERVIEW">ソラシロ合同会社<span>sorashiro LLC.</span></h3>
            <div class="overview" data-aos="fade-right" data-aos-offset="200">
              <dl>
                <dt data-aos="fade-right" data-aos-offset="200">会社名</dt>
                <dd data-aos="fade-right" data-aos-offset="200" data-aos-delay="300">ソラシロ合同会社<span>sorashiro LLC.</span></dd>
                <dt data-aos="fade-right" data-aos-offset="200">設立</dt>
                <dd data-aos="fade-right" data-aos-offset="200" data-aos-delay="300">2016年7月</dd>
                <dt data-aos="fade-right" data-aos-offset="200">代表</dt>
                <dd data-aos="fade-right" data-aos-offset="200" data-aos-delay="300">鯉渕 千泰</dd>
                <dt data-aos="fade-right" data-aos-offset="200">スタッフ</dt>
                <dd data-aos="fade-right" data-aos-offset="200" data-aos-delay="300">8名 ※外部スタッフ含む</dd>
                <dt data-aos="fade-right" data-aos-offset="200">事業内容</dt>
                <dd data-aos="fade-right" data-aos-offset="200" data-aos-delay="300">ゲーム制作、インタラクティブコンテンツ制作、アプリ制作、WEB制作</dd>
                <!-- <dt>ADRESS</dt><dd>3-4-301, Sumiyoshicho, Naka-ku Hiroshima-shi, Hiroshima, 730-0813, Japan</dd> -->
                <dt data-aos="fade-right" data-aos-offset="200">住所</dt>
                <dd data-aos="fade-right" data-aos-offset="200" data-aos-delay="300">〒730-0802 広島市中区本川町2丁目1-14 3F東</dd>
                <dt data-aos="fade-right" data-aos-offset="200">電話</dt>
                <dd data-aos="fade-right" data-aos-offset="200" data-aos-delay="300">{{ companyInfo('tel') }} </dd>
                <dt data-aos="fade-right" data-aos-offset="200">メール</dt>
                <dd data-aos="fade-right" data-aos-offset="200" data-aos-delay="300">info@sorashiro.co.jp</dd>
              </dl>
            </div>
      <ul class="office-img">
        <li data-aos="fade-left" data-aos-offset="200" data-aos-delay="0"><img src="../assets/pic_office_01.png" alt=""></li>
        <li data-aos="fade-left" data-aos-offset="200" data-aos-delay="200"><img src="../assets//pic_office_02.png" alt=""></li>
        <li data-aos="fade-left" data-aos-offset="200" data-aos-delay="400"><img src="../assets/pic_office_03.png" alt=""></li>
      </ul>
          </div>
        </section>
        <!-- アクセス -->
        <section class="access">
          <h2 title="アクセス">ACCESS</h2>
          <div class="content">
            <iframe data-aos="fade-left" data-aos-offset="100" data-aos-delay="300" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3292.147267567018!2d132.4494931152241!3d34.39760258051344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xaceeba9d9b68bd9e!2z44K944Op44K344Ot5ZCI5ZCM5Lya56S-IC0gc29yYXNoaXJvIExMQy4!5e0!3m2!1sja!2sjp!4v1579839531137!5m2!1sja!2sjp" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
            <p>広島電鉄 本川町駅(M11)より徒歩5分。中川産科婦人科医院の隣のビルの3階です</p>
          </div>
        </section>
        <ToTop />
        <ToContact />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  metaInfo: {
    title: 'ソラシロについて',
    bodyAttrs: {
      id: 'pg-about'
    },
    meta: [
      { name: 'description', content: '私たちソラシロはUnityゲームエンジンを用いて、常識やハードに囚われず、真っ白な視点から豊かな体験と経験を提供します。' }
    ],
    link: [
      { rel: 'canonical', href: 'https://sorashiro.com/about/' }
    ]
  },
  mounted: function () {
    this.$nextTick(function () {
      this.initGsap(this.$isMobile())
    })
  },
  computed: {
    ...mapGetters(['companyInfo'])
  },
  beforeDestroy: function () {
    this.removeGsap()
  }
}
</script>

<style lang="scss" scoped>

  $zure: 50px; //写真のズレ具合

  ul.office-img{
    position: relative;
    text-align: left;
    white-space: nowrap;
    margin: 6em auto 0 -3em;
    padding-left: 0;
    text-align: center;

    li{
      display: inline-block;
      margin-right: 5vw;
      vertical-align: top;
      position: relative;

      &:before{
        content: "";
        background: url('../assets/hmd_circle2.svg') no-repeat;
        background-size: contain;
        position: absolute;
        display: inline-block;
        left: -5px;
        right: -5px;
        top: -5px;
        bottom: 5px;
        width: 300px;
        height: 100%;
      }

      &:last-child{
        margin-right: 0;
      }
      &:nth-child(2){
        margin-top: $zure;
      }
      &:nth-child(3){
        margin-top: $zure * 2;
      }
    }

    @include mq-max(){
      left: 0;
      li{
        // display: block;
        &:last-child{
          margin-right: 0;
        }
        &:nth-child(2){
          margin-top: $zure/2;
        }
        &:nth-child(3){
          margin-top: $zure * 2/2;
        }
      }
      img{
        width: 80px;
      }
    }

  } // end of .office-img

.access{

  iframe {
    width: 100%;
    height: 400px;
    // box-shadow: 0 0 6px 0px rgba(255,255,255,1);
  }

  h3{
    right: 90%;
  }

  @include mq-max(){
    iframe{
      width: 100%;
    }
  }
}

</style>
